(function () {
	'use strict';

	angular
		.module('app')
		.factory('googleSheets', [
			'googleSheetsConfig',
			'$location',
			'seedcodeCalendar',
			'calendarIO',
			'fullCalendarBridge',
			'daybackIO',
			'utilities',
			'dataStore',
			'manageSettings',
			'manageFetch',
			googleSheets,
		]);

	function googleSheets(
		googleSheetsConfig,
		$location,
		seedcodeCalendar,
		calendarIO,
		fullCalendarBridge,
		daybackIO,
		utilities,
		dataStore,
		manageSettings,
		manageFetch
	) {
		//Create our connection for api services
		var connection = createConnection();

		var eventEditQueue = {};

		var eventCache = {};
		var eventsInitialized;

		var primarySource;
		var primarySourceTemplate;

		gSheet.setErrorReporter(errorReporter);

		return {
			getConfig: getConfig,
			getFieldMap: getFieldMap,
			getUnusedMap: getUnusedMap,
			getAllowHTMLMap: getAllowHTMLMap,
			getHiddenFieldMap: getHiddenFieldMap,
			getReadOnlyFieldMap: getReadOnlyFieldMap,
			getAllowTextFieldMap: getAllowTextFieldMap,
			getEventEditQueue: getEventEditQueue,
			auth: auth,
			switchAccount: switchAccount,
			deauthorize: deauthorize,
			getSchedules: getSchedules,
			changeScheduleName: changeScheduleName,
			changeScheduleColor: changeScheduleColor,
			disableSchedule: disableSchedule,
			refresh: refresh,
			getEvents: getEvents,
			editEvent: editEvent,
			deleteEvent: deleteEvent,
			updateRepeatingEventUntil: updateRepeatingEventUntil,
			deleteRepeatingInstance: deleteRepeatingInstance,
			onSignOut: onSignOut,
		};

		function getConfig() {
			return googleSheetsConfig.config();
		}

		function getFieldMap() {
			return googleSheetsConfig.fieldMap();
		}

		function getUnusedMap() {
			return googleSheetsConfig.unusedMap();
		}

		function getAllowHTMLMap() {
			return googleSheetsConfig.allowHTMLMap();
		}

		function getHiddenFieldMap() {
			return googleSheetsConfig.hiddenFieldMap();
		}

		function getReadOnlyFieldMap() {
			return googleSheetsConfig.readOnlyFieldMap();
		}

		function getAllowTextFieldMap() {
			return false;
		}

		function getEventEditQueue() {
			return eventEditQueue;
		}

		function createConnection() {
			var connection = googleSheetsConfig.apiConnection();

			// Do something with api connection details here or just reference using connection var created at the top of the file

			return connection;
		}

		function errorReporter(
			status,
			additionalMessage,
			preventDeauth,
			callback
		) {
			var config = seedcodeCalendar.get('config');

			var message = 'There was a problem communicating with Google.';

			if (config.passthroughEditErrors) {
				return;
			}

			if (additionalMessage) {
				message += ' ' + additionalMessage;
			}

			if (!preventDeauth && (status === 401 || status === 403)) {
				message += ' - ' + 'please re-authorize DayBack.';
				if (primarySourceTemplate)
					deauthorize(callback, false, primarySourceTemplate);
			}
			if (!config.suppressEditEventMessages) {
				utilities.showMessage(message, 0, 8000, 'error');
			}
		}

		function deleteRepeatingInstance(event, callback) {
			// Function called when the user attempts to delete a repeating event (has eventRepetitionID)
		}

		function updateRepeatingEventUntil(event, untilMoment, callback) {
			// Function called when updating repeating event with until set
		}

		function auth(callback, source, statusOnly, forceConsent) {
			var user = daybackIO.getUser();
			if (!source) {
				source = primarySource;
			}
			gSheet.auth(
				user.id,
				source.id,
				statusOnly,
				forceConsent,
				connection.authIsRedirect,
				connection.authRedirectFunction,
				callback
			);

			// Run auth function if no source is supplied it will auth into first one loaded (this should work unless you allow multiple simultaneous accounts)
			// Make sure to run callback when complete if the result of the callback is a false value it is assumed auth was cancelled. A truthy value assumes success
		}

		function switchAccount(callback, sourceTemplate) {
			auth(
				function (authSuccess) {
					if (!authSuccess) {
						return;
					}
					deauthorize(
						function () {
							sourceTemplate.authFunction(callback);
						},
						true,
						sourceTemplate
					);
				},
				null,
				false,
				true
			);
		}

		function deauthorize(callback, saveToken, sourceTemplate) {
			var user = daybackIO.getUser();
			var source = primarySource;
			var schedules = seedcodeCalendar.get('schedules');
			var calendarElement = seedcodeCalendar.get('element');
			var eventSources = seedcodeCalendar.get('eventSources');

			var retainedSchedules = [];
			var removedSchedules = [];

			// Run deauthorize routine here and call deathComplete when done
			gSheet.deauthorize(user.id, source.id, saveToken, deauthComplete);

			function deauthComplete() {
				// Remove auth status
				sourceTemplate.status.authed = false;

				// Only try to update calendar if it exists - Not used when initiate from settings
				if (seedcodeCalendar.get('view')) {
					// Remove Schedules and events
					for (var i = 0; i < schedules.length; i++) {
						if (schedules[i].sourceID !== source.id) {
							retainedSchedules.push(schedules[i]);
						} else {
							removedSchedules.push(schedules[i]);
						}
					}

					seedcodeCalendar.init('schedules', retainedSchedules);

					for (var i = 0; i < removedSchedules.length; i++) {
						// Remove event sources from fullcalendar
						calendarElement.fullCalendar(
							'removeEventSource',
							removedSchedules[i].source,
							removedSchedules[i].id
						);

						//Remove event source from eventSourcesObject
						if (
							eventSources.indexOf(removedSchedules[i].source) >
							-1
						) {
							eventSources.splice(
								eventSources.indexOf(
									removedSchedules[i].source
								),
								1
							);
						}
					}

					// Remove orphanes
					var events = calendarElement.fullCalendar('clientEvents');
					for (var i = 0; i < events.length; i++) {
						if (events[i].schedule.sourceID === source.id) {
							calendarElement.fullCalendar(
								'removeEvents',
								events[i]
							);
						}
					}
				}

				if (callback) {
					callback();
				}
			}
		}

		function getSchedules(callback, sourceTemplate) {
			var config = seedcodeCalendar.get('config');
			var sources = seedcodeCalendar.get('sources');
			var userProfile = gSheet.getUserProfile();
			var schedules = [];
			var schedule;
			var source;

			primarySourceTemplate = sourceTemplate;
			//Locate our dayback source data for google
			for (var i = 0; i < sources.length; i++) {
				if (sources[i].sourceTypeID === sourceTemplate.id) {
					source = sources[i];

					//Run our authorization
					if (sources[i].localParent) {
						if (userProfile) {
							source.name = userProfile.name;
							source.email = userProfile.email;
						}

						primarySource = source;
						auth(authCallBack, source, true);
						break;
					}
				}
			}

			return [];

			function authCallBack(auth) {
				if (auth) {
					getCalendarList();
				} else {
					callback(false, sourceTemplate);
				}
				sourceTemplate.status.authed = auth;
			}

			function getCalendarList() {
				// Run routine to get list of calendars then run calendarListCallBack on success
				for (var i = 0; i < sources.length; i++) {
					if (
						sources[i].sourceTypeID === sourceTemplate.id &&
						sources[i].id !== primarySource.id
					) {
						schedule = setSchedule(sources[i]);
						schedules.push(schedule);
					}
				}

				callback(schedules, sourceTemplate);
			}

			function setSchedule(source) {
				var schedule = {};

				schedule.customFields = primarySource.customFields
					? JSON.parse(JSON.stringify(primarySource.customFields))
					: null;
				schedule.customActions = primarySource.customActions
					? JSON.parse(JSON.stringify(primarySource.customActions))
					: null;
				schedule.eventActions = primarySource.eventActions
					? JSON.parse(JSON.stringify(primarySource.eventActions))
					: null;

				//Append schedule specific items to already specified source items
				for (var property in source) {
					if (
						property === 'eventActions' ||
						property === 'customActions' ||
						property === 'customFields'
					) {
						if (!schedule[property]) {
							schedule[property] = source[property]
								? JSON.parse(JSON.stringify(source[property]))
								: null;
						} else {
							for (var calendarProperty in source[property]) {
								schedule[property][calendarProperty] =
									source[property][calendarProperty];
							}
						}
					} else {
						schedule[property] = source[property]
							? JSON.parse(JSON.stringify(source[property]))
							: null;
					}
				}

				//Clean schedule data
				schedule.sourceID = primarySource.id;
				//Normalize read only
				schedule.editable = sourceTemplate.editable;

				// Make sure the sheetID is just the ID and not the full URL
				schedule.sheetID = !schedule.sheetID
					? null
					: schedule.sheetID.indexOf('/spreadsheets/d/') > -1
						? schedule.sheetID
								.split('/spreadsheets/d/')[1]
								.split('/edit')[0]
						: schedule.sheetID;

				// Make sure the first data row is set to a number
				schedule.firstDataRow = schedule.firstDataRow
					? Number(schedule.firstDataRow)
					: 1;
				schedule.lastDataRow = schedule.lastDataRow
					? Number(schedule.lastDataRow)
					: '';

				// Set source specific custom field properties
				if (schedule.customFields) {
					for (var property in schedule.customFields) {
						schedule.customFields[property].mutateInput =
							customFieldMutateInput;
					}
				}

				//Clean schedule data
				calendarIO.cleanSchedule(schedule, sourceTemplate);

				return schedule;
			}

			function applySchedules(schedules, sourceID) {
				var scheduleID;

				//Clean schedule data
				for (var i = 0; i < schedules.length; i++) {
					//Merge source data that is missing from the returned calendar / schedule
					for (var property in source) {
						if (schedules[i][property] === undefined) {
							schedules[i][property] = source[property];
						}
					}

					schedules[i].sourceID = sourceID;

					//Add dayback source data to schedules because this is not a 1:1 source type
					schedules[i].customFields = source.customFields
						? JSON.parse(JSON.stringify(source.customFields))
						: null;
					schedules[i].customActions = source.customActions
						? JSON.parse(JSON.stringify(source.customActions))
						: null;
					schedules[i].eventActions = source.eventActions
						? JSON.parse(JSON.stringify(source.eventActions))
						: null;
					schedules[i].fieldMap = source.fieldMap
						? JSON.parse(JSON.stringify(source.fieldMap))
						: null;
					schedules[i].unusedMap = source.unusedMap
						? JSON.parse(JSON.stringify(source.unusedMap))
						: null;
					schedules[i].allowHTMLMap = source.allowHTMLMap
						? JSON.parse(JSON.stringify(source.allowHTMLMap))
						: null;
					schedules[i].labelMap = source.labelMap
						? JSON.parse(JSON.stringify(source.labelMap))
						: null;

					scheduleID = utilities.stringToID(schedules[i].id);

					if (source[scheduleID]) {
						//Append schedule specific items to already specified source items
						for (var property in source[scheduleID]) {
							if (
								schedules[i][property] &&
								(property === 'eventActions' ||
									property === 'customActions' ||
									property === 'customFields' ||
									property === 'fieldMap' ||
									property === 'unusedMap' ||
									property === 'allowHTMLMap' ||
									property === 'labelMap')
							) {
								for (var calendarProperty in source[scheduleID][
									property
								]) {
									if (
										property === 'fieldMap' ||
										property === 'unusedMap' ||
										property === 'allowHTMLMap' ||
										property === 'labelMap'
									) {
										if (
											!schedules[i][property][
												calendarProperty
											]
										) {
											schedules[i][property][
												calendarProperty
											] =
												source[scheduleID][property][
													calendarProperty
												];
										}
									} else {
										schedules[i][property][
											calendarProperty
										] =
											source[scheduleID][property][
												calendarProperty
											];
									}
								}
							} else {
								schedules[i][property] =
									sourceTemplate.settings[property] &&
									!sourceTemplate.settings[property]
										.scheduleOnly &&
									source[property] !== 'disable-global'
										? source[property]
										: source[scheduleID][property];
							}
						}
					}

					//Set source specific custom field properties
					if (schedules[i].customFields) {
						for (var property in schedules[i].customFields) {
							schedules[i].customFields[property].mutateInput =
								customFieldMutateInput;
						}
					}

					calendarIO.cleanSchedule(schedules[i], sourceTemplate);
					//Normalize read only
					schedules[i].editable = sourceTemplate.editable;

					//Normalize the calendar name
					schedules[i].name = schedules[i].summary;
				}
				callback(schedules, sourceTemplate);
			}
		}

		function changeScheduleName(name, callback, schedule) {
			manageSettings.setCalendarName(name, schedule, callback);
			return name;
		}

		function changeScheduleColor(color, callback, schedule) {
			manageSettings.setBackgroundColor(color, schedule, callback);
			return color;
		}

		function disableSchedule(scheduleID) {
			manageFetch.clear(scheduleID);
		}

		function refresh(schedule, callback) {
			eventsInitialized = null;
			eventCache = {};
			if (callback) {
				callback();
			}
		}

		function getEvents(
			start,
			end,
			timezone,
			callbackFunc,
			schedule,
			options,
			eventID,
			fetchID,
			requestOverride
		) {
			var calendarElement = seedcodeCalendar.get('element');
			var config = seedcodeCalendar.get('config');
			var view = seedcodeCalendar.get('view');
			var fieldMap = schedule.fieldMap;
			var queryDate = calendarElement.fullCalendar('getDate');

			//find events for December 2014 for the user's first calendar.
			var calendarID = schedule.sheetID;
			var sheetName = schedule.sheetName;
			var queryStart = moment(start).format();
			var queryEnd = moment(end).format();
			var queryDayRange = end.diff(start, 'days');

			var callback = manageFetch.create(
				schedule.id,
				'event',
				callbackFunc
			).confirm;

			// Run routine to get Events and then run processEvents function passing event list as callback
			if (!eventCache[schedule.id]) {
				// Run before events fetched action
				const actionCallbacks = {
					confirm: function () {
						gSheet.eventList(
							calendarID,
							sheetName,
							fieldMap,
							schedule,
							processEvents
						);
					},
					cancel: function () {
						callback(mutateEvents([], schedule, null));
					},
				};

				const actionResult = calendarIO.runEventActions(
					'beforeEventsFetched',
					{schedule: schedule},
					true,
					actionCallbacks,
					null
				);

				if (!actionResult) {
					return;
				}

				gSheet.eventList(
					calendarID,
					sheetName,
					fieldMap,
					schedule,
					processEvents
				);
			} else {
				//Wrap in a settimeout so we make sure this is async as we expect these calls to be
				window.setTimeout(function () {
					processEvents(eventCache[schedule.id]);
				}, 0);
			}

			function processEvents(result) {
				if (!result || result.error) {
					schedule.error = result.error;
					callback();
					return;
				}

				eventCache[schedule.id] = JSON.parse(JSON.stringify(result));

				callback(mutateEvents(result, schedule, null));
			}
		}

		function mutateEvents(events, schedule, customizedRepeats) {
			const hookResult = calendarIO.mutateHook();
			if (!hookResult) {
				return [];
			}

			var customFields = schedule.customFields;
			var fieldMap = schedule.fieldMap;
			var output = [];

			for (var i = 0; i < events.length; i++) {
				if (events[i]) {
					output.push(mutate(events[i], i));
				}
			}

			return output;

			function mutate(event, row) {
				var fieldMap = schedule.fieldMap;
				event.eventSource = schedule.id;
				event.schedule = schedule;
				event.color = schedule.backgroundColor;
				event.textColor = schedule.foregroundColor;
				//field specific transformations

				// Test for all day
				event.allDay =
					event.start && event.start.indexOf(':') > -1 ? false : true;

				for (var property in fieldMap) {
					if (
						property === 'status' ||
						property === 'resource' ||
						property === 'contactName' ||
						property === 'contactID' ||
						property === 'projectName' ||
						property === 'projectID'
					) {
						//convert to array
						event[property] =
							!event[property] || event[property] === ''
								? []
								: event[property].split(',');
					} else if (
						property === 'start' ||
						(property === 'end' && event[property])
					) {
						if (event.allDay) {
							event[property] = moment(
								event[property],
								schedule.fileDateFormat
							);
						} else {
							event[property] = moment(
								event[property],
								schedule.fileDateFormat +
									' ' +
									schedule.fileTimeFormat
							);
						}
					} else {
						event[property] = event[property];
					}
				}

				if (!event.end) {
					event.end = event.start.clone();
				}

				// Prep geocode
				if (event.geocode) {
					const geocodeParts = event.geocode.join(',');
					event.geocode = {
						lat: geocodeParts[0]?.trim(),
						lng: geocodeParts[1]?.trim(),
					};
				}

				//return mulated event
				return calendarIO.cleanEvent(event, true);
			}
		}

		function customFieldMutateInput(item, fieldDefinition) {
			var result;
			if (fieldDefinition.formatas === 'select') {
				if (!item) {
					result = [];
				} else if (Array.isArray(item)) {
					result = item;
				} else {
					try {
						result = JSON.parse(item);
						if (!Array.isArray(result)) {
							result = [item];
						}
					} catch (error) {
						result = [item];
					}
				}
				calendarIO.replaceWithNoFilterValue(result);
			} else {
				result = item;
			}
			return result;
		}

		function customFieldMutateOutput(item, customField, schedule) {
			if (customField && customField.formatas === 'date') {
				return item ? moment(item).format(schedule.fileDateFormat) : '';
			} else if (customField && customField.formatas === 'timestamp') {
				return item
					? moment(item).format(
							schedule.fileDateFormat +
								' ' +
								schedule.fileTimeFormat
						)
					: '';
			} else if (Array.isArray(item)) {
				calendarIO.removeNoFilterValue(item);
				return JSON.stringify(item);
			} else {
				return item;
			}
		}

		//Edit Events
		function editEvent(
			event,
			revertObject,
			revertFunc,
			changes,
			editID,
			callback,
			schedule,
			sourceEventId
		) {
			var config = seedcodeCalendar.get('config');
			var customFields = schedule.customFields;
			var fieldMap = schedule.fieldMap;
			var editObj = {};
			var additionalEditProperties = {};
			var until;
			var view;
			var id = event.eventID;

			for (var property in fieldMap) {
				//Don't send unused / unmapped items to edit object
				if (!fieldMap[property] || schedule.unusedMap?.[property]) {
					continue;
				}

				//If the field is mapped and it has a change property, or if it is a new event write all non empty fields
				if (
					fieldMap[property] &&
					property !== 'eventID' &&
					property !== 'title' &&
					changes.hasOwnProperty(property)
				) {
					//format start and stop as needed
					//don't populate these if we're doing a source event
					if (
						(property === 'start' || property === 'end') &&
						!sourceEventId
					) {
						editObj[property] = changes.allDay
							? moment(changes[property].format('YYYY-MM-DD'))
							: moment(changes[property]);
					} else if (
						property === 'resource' ||
						property === 'status'
					) {
						editObj[property] = changes[property].slice(); //Using slice here to clone the array
						//Remove "none" label from resources so we don't write it back to the source
						for (var i = 0; i < editObj[property].length; i++) {
							if (editObj[property][i] === config.noFilterLabel) {
								editObj[property][i] = '';
							}
						}
					} else if (property === 'geocode') {
						editObj[property] =
							`${changes[property].lat},${changes[property].lng}`;
					} else if (property === 'tags') {
						editObj[property] = calendarIO.packageTagsOutput(
							event[property]
						);
					} else if (customFields && customFields[property]) {
						editObj[fieldMap[property]] =
							calendarIO.customFieldOutputTransform(
								property,
								changes[property],
								customFields,
								schedule,
								customFieldMutateOutput
							);
						//Adjust custom field data types to strings
						editObj[fieldMap[property]] =
							editObj[fieldMap[property]] === undefined
								? ''
								: editObj[fieldMap[property]].toString();
					} else if (property === 'eventSource') {
						//don't do anything as we don't want to add eventSource to editObj
					}
					//Join arrays
					else if (Array.isArray(event[property])) {
						editObj[property] = changes[property].slice(); //Use slice here to clone the array rather than using a reference
					} else {
						editObj[property] = changes[property];
					}
				}
			}

			//Explicitely set timezone as it is currently a non standard field
			editObj.timezone = schedule.timeZone;
			//Apply additional event properties necessary to properly edit the event
			additionalEditProperties.allDay = event.allDay;
			additionalEditProperties.attendees = event.attendees;

			//Check if we should be changing the calendar
			if (changes.eventSource) {
				//Change calendar
				// Run routine to change calendar and call changeCalendarSuccess callback
			}
			//If not just apply our event changes
			else {
				//Edit the event
				applyEditEvent();
			}

			//Callback for changing the calendar
			function changeCalendarSuccess(result) {
				if (result && result.error) {
					resultCallback(result);
				} else {
					//Edit the event
					applyEditEvent();
				}
			}
			//Apply our event result (write result data)
			function applyEditEvent() {
				//if we have an id we're updating
				if (event.eventID) {
					if (event.recurringEventID || editObj.recurrence) {
						view = seedcodeCalendar.get('view');
						until = moment(view.end.format());
					} else {
						until = false;
					}
					// Call routine to update event and call result callback
				} else {
					if (editObj.recurrence) {
						view = seedcodeCalendar.get('view');
						until = moment(view.end.format());
					} else {
						until = false;
					}
					// Call routine to create an event and call result callback
				}
			}
			function resultCallback(result) {
				var element = seedcodeCalendar.get('element');
				var eventResult;
				var aResult = !result.items ? [result] : result.items;
				var message;
				var modified;
				var domEvents;
				var newSeries;

				//If we are dragging this event currently we need to wait and see if the event has changed or not
				if (eventEditQueue.dragging) {
					window.setTimeout(function () {
						resultCallback(result);
					}, 250);
					return;
				}

				//We are editing the same event before the last save so exit the save
				if (editID && eventEditQueue[event.eventID] > editID) {
					return;
				} else {
					delete eventEditQueue[event.eventID];
				}

				//Check for errors and if we detect one revert the record
				if (result && result.error) {
					if (config.passthroughEditErrors) {
						if (callback) {
							callback(result);
						}
					} else if (config.suppressEditEventMessages) {
						dragError();
					} else if (revertFunc) {
						message =
							'There was an error saving the event and your changes will be reverted: ' +
							result.error.message;
						utilities.showModal(
							'Operation Failed',
							message,
							null,
							null,
							'Revert Changes',
							dragError
						);
					} else if (revertObject) {
						//applyEventChanges(event, revertObject); //Would revert the event but we don't want that in this case as we don't want to lose changes
						message =
							'There was an error and your event could not be saved: ' +
							result.error.message;
						utilities.showModal(
							'Operation Failed',
							message,
							'Return To Event',
							cancelError,
							'Revert Changes',
							confirmError
						);
					}
					return;
				}

				//No errors found continue with updating the event with the returned data
				aResult = mutateEvents(aResult, schedule);

				if (until) {
					element = seedcodeCalendar.get('element');
					domEvents = element.fullCalendar('clientEvents');
				}

				if (editObj.recurrence && editObj.recurrence.length > 0) {
					newSeries = true;
				}

				for (var i = 0; i < aResult.length; i++) {
					eventResult = aResult[i];

					eventResult.allDay = event.allDay;
					eventResult._allDay = eventResult.allDay;

					if (aResult.length === 1 || (newSeries && i === 0)) {
						// Normalize dates
						seedcodeCalendar
							.get('element')
							.fullCalendar(
								'buildEventDates',
								eventResult,
								eventResult
							);

						modified = applyEventChanges(event, eventResult);
						if (modified) {
							fullCalendarBridge.update(element, event);
						}
					} else if (newSeries) {
						seedcodeCalendar
							.get('element')
							.fullCalendar('renderEvent', eventResult);
					} else {
						for (var ii = 0; ii < domEvents.length; ii++) {
							if (eventResult.eventID === domEvents[ii].eventID) {
								//case 49365. There's a difference when fullCalendarBridge.update(element, domEvents[ii]);
								//is used that causes a change false positive on "end" when opened and closed in the popover
								//working around this by removing and re-rendering - small performance difference
								element.fullCalendar(
									'removeEvents',
									domEvents[ii]
								);
								seedcodeCalendar
									.get('element')
									.fullCalendar('renderEvent', eventResult);
							}
						}
					}
				}

				if (callback) {
					callback(event);
				}

				//Define error functions for this edit
				function dragError() {
					if (revertFunc) {
						revertFunc(null, event, result.error);
					}
				}
				function confirmError() {
					if (event.eventID) {
						applyEventChanges(event, revertObject);
						calendarIO.assignEventColor(event);
						fullCalendarBridge.update(element, event);
						if (callback) {
							callback(false);
						}
					} else {
						//Remove event from view
						fullCalendarBridge.removeEvents(element, event);
					}
				}
				function cancelError() {
					if (!event.eventStatus) {
						event.eventStatus = {};
					}

					event.eventStatus.revertObject = revertObject;

					if (event.eventStatus.isCustomAction) {
						applyEventChanges(event, revertObject);
					} else {
						event.eventStatus.showPopover = true;
						event.eventStatus.unsavedChanges = true;
						fullCalendarBridge.update(element, event);
						//Reset event status for custom action state
						event.eventStatus.isCustomAction = false;
					}
				}
			}
		}

		//Apply event changes returned from backend after an edit. Also returns wether the event was modified on the server
		function applyEventChanges(event, changes) {
			var modified;
			//Check if we need to apply a new schedule
			if (changes.schedule && event.schedule.id !== changes.schedule.id) {
				modified = true;
				event.schedule = changes.schedule;
			}
			//Loop through all properties and set those
			for (var property in changes) {
				//Check string, numbers, and booleans
				if (
					(typeof changes[property] === 'undefined' ||
						typeof changes[property] === 'string' ||
						typeof changes[property] === 'number' ||
						typeof changes[property] === 'boolean' ||
						(typeof changes[property] === 'object' &&
							changes[property] === null)) &&
					property !== 'dateStart' &&
					property !== 'dateEnd' &&
					property !== 'timeStart' &&
					property !== 'timeEnd' &&
					property !== 'start' &&
					property !== 'end'
				) {
					if (
						(event[property] === undefined ||
							event[property] === null ||
							event[property] === '') &&
						(changes[property] === undefined ||
							changes[property] === null ||
							changes[property] === '')
					) {
						// we have a null value so let's just set back to event to normalize but don't mark anything as modified
						event[property] = changes[property];
					} else if (event[property] !== changes[property]) {
						if (property !== 'eventID') {
							modified = true;
						}
						event[property] = changes[property];
					}
				}

				//Check arrays
				else if (Array.isArray(changes[property])) {
					if (
						!utilities.arraysEqual(
							event[property],
							changes[property]
						)
					) {
						modified = true;
						event[property] = changes[property];
					}
				}
				//Check moment objects
				else if (
					moment.isMoment(changes[property]) ||
					property === 'start' ||
					property === 'end'
				) {
					if (
						!moment(changes[property]).isSame(
							moment(event[property])
						)
					) {
						modified = true;
						event[property] = changes[property];
					}
				}
			}
			return modified;
		}

		//Delete Event
		function deleteEvent(event, callback, schedule) {
			// Run routine to delete event and call processDelete callback when done

			function processDelete(result) {
				var message;
				if (result) {
					message =
						'There was an error deleting the event: ' +
						result.error.message;
					utilities.showModal(
						'Operation Failed',
						message,
						null,
						null,
						'Return To Event',
						confirmError
					);
					return;
				}
				calendarIO.confirmDelete(event, 0, callback);
			}
			function confirmError() {
				return;
			}
		}

		function onSignOut() {
			gSheet.clearTokenMemory();
		}
	}
})();
